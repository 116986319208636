export enum Location {
    CERULEAN = 'Cerulean',
    SAFFRON = 'Saffron',
    CINNABAR = 'Cinnabar',
    BUTTERFREE_ISLAND = 'Butterfree Island',
    VIRIDIAN = 'Viridian',
    PEWTER = 'Pewter',
    CELADON = 'Celadon',
    VERMILION = 'Vermilion',
    FUCHSIA = 'Fuchsia',
    HAMLIN = 'Hamlin',
    MANDARIN = 'Mandarin',
    SHAMOUTI = 'Shamouti',
    CREATION_STUDIO = 'Creation Studio',
    ORRE = 'Orre',
    ASCORBIA = 'Ascorbia',
    LEAF_ISLAND = 'Leaf Island',
    BUTWAL = 'Butwal',
    MAGMA_ISLAND = 'Magma Island',
    MURCOTT = 'Murcott',
    TANGELO_ISLAND = 'Tangelo Island',
    PETALGROOVE_ISLAND = 'Petalgroove Island',
    PHENAC = 'Phenac',
    AGATE = 'Agate',
    GATEON = 'Gateon',
    OUTLAND_SOUTH = 'Outland South',
    OUTLAND_EAST = 'Outland East',
    OUTLAND_NORTH = 'Outland North',
}
