export type ExpPerLevel = {
    level: number
    exp: number
}
export const expPerLevel: ExpPerLevel[] = [
    { level: 26, exp: 232266 },
    { level: 51, exp: 1964983 },
    { level: 76, exp: 6756124 },
    { level: 27, exp: 262591 },
    { level: 52, exp: 2085780 },
    { level: 77, exp: 7035598 },
    { level: 3, exp: 199 },
    { level: 28, exp: 294668 },
    { level: 53, exp: 2214246 },
    { level: 78, exp: 7322674 },
    { level: 4, exp: 388 },
    { level: 29, exp: 330110 },
    { level: 54, exp: 2347882 },
    { level: 79, exp: 7611213 },
    { level: 5, exp: 790 },
    { level: 30, exp: 368290 },
    { level: 55, exp: 2483765 },
    { level: 80, exp: 7913640 },
    { level: 6, exp: 1498 },
    { level: 31, exp: 408349 },
    { level: 56, exp: 2627936 },
    { level: 81, exp: 8223973 },
    { level: 7, exp: 2565 },
    { level: 32, exp: 452248 },
    { level: 57, exp: 2777581 },
    { level: 82, exp: 8535590 },
    { level: 8, exp: 4176 },
    { level: 33, exp: 499189 },
    { level: 58, exp: 2929438 },
    { level: 83, exp: 8861876 },
    { level: 9, exp: 6397 },
    { level: 34, exp: 548118 },
    { level: 59, exp: 3090220 },
    { level: 84, exp: 9196372 },
    { level: 10, exp: 9230 },
    { level: 35, exp: 601380 },
    { level: 60, exp: 3256780 },
    { level: 85, exp: 9531955 },
    { level: 11, exp: 12956 },
    { level: 36, exp: 657988 },
    { level: 61, exp: 3425499 },
    { level: 86, exp: 9883006 },
    { level: 12, exp: 17596 },
    { level: 37, exp: 716675 },
    { level: 62, exp: 3603798 },
    { level: 87, exp: 10242571 },
    { level: 13, exp: 23083 },
    { level: 38, exp: 780206 },
    { level: 63, exp: 3788179 },
    { level: 88, exp: 10603008 },
    { level: 14, exp: 29830 },
    { level: 39, exp: 847387 },
    { level: 64, exp: 3974648 },
    { level: 89, exp: 10979730 },
    { level: 15, exp: 37795 },
    { level: 40, exp: 916720 },
    { level: 65, exp: 4171370 },
    { level: 90, exp: 11365270 },
    { level: 16, exp: 46824 },
    { level: 41, exp: 991426 },
    { level: 66, exp: 4374478 },
    { level: 91, exp: 11751449 },
    { level: 17, exp: 57489 },
    { level: 42, exp: 1070086 },
    { level: 67, exp: 4579585 },
    { level: 92, exp: 12154748 },
    { level: 18, exp: 69694 },
    { level: 43, exp: 1150953 },
    { level: 68, exp: 4795636 },
    { level: 93, exp: 12567169 },
    { level: 19, exp: 83153 },
    { level: 44, exp: 1237740 },
    { level: 69, exp: 5018377 },
    { level: 94, exp: 12979978 },
    { level: 20, exp: 96660 },
    { level: 45, exp: 1328785 },
    { level: 70, exp: 5243010 },
    { level: 95, exp: 13410760 },
    { level: 21, exp: 115993 },
    { level: 46, exp: 1422074 },
    { level: 71, exp: 5479296 },
    { level: 96, exp: 13850968 },
    { level: 22, exp: 134770 },
    { level: 47, exp: 1521848 },
    { level: 72, exp: 5722576 },
    { level: 97, exp: 14291295 },
    { level: 23, exp: 156016 },
    { level: 48, exp: 1626184 },
    { level: 73, exp: 5967623 },
    { level: 98, exp: 14750466 },
    { level: 24, exp: 179392 },
    { level: 49, exp: 1732783 },
    { level: 74, exp: 6225050 },
    { level: 99, exp: 15219367 },
    { level: 25, exp: 204375 },
    { level: 50, exp: 1846450 },
    { level: 75, exp: 6489775 },
    { level: 100, exp: 15688100 },
    { level: 101, exp: 16176566 },
    { level: 126, exp: 31787458 },
    { level: 151, exp: 55129749 },
    { level: 176, exp: 87804616 },
    { level: 102, exp: 16675066 },
    { level: 127, exp: 32551805 },
    { level: 152, exp: 56254848 },
    { level: 177, exp: 89337541 },
    { level: 103, exp: 17173093 },
    { level: 128, exp: 33344696 },
    { level: 153, exp: 57395149 },
    { level: 178, exp: 90856518 },
    { level: 104, exp: 17691760 },
    { level: 129, exp: 34150357 },
    { level: 154, exp: 58527038 },
    { level: 179, exp: 92424660 },
    { level: 105, exp: 18220765 },
    { level: 130, exp: 34951990 },
    { level: 155, exp: 59697740 },
    { level: 180, exp: 94010740 },
    { level: 106, exp: 18748974 },
    { level: 131, exp: 35783236 },
    { level: 156, exp: 60883948 },
    { level: 181, exp: 95582099 },
    { level: 107, exp: 19298748 },
    { level: 132, exp: 36627556 },
    { level: 157, exp: 62061115 },
    { level: 182, exp: 97203998 },
    { level: 108, exp: 19859164 },
    { level: 133, exp: 37467363 },
    { level: 158, exp: 63278326 },
    { level: 183, exp: 98844139 },
    { level: 109, exp: 20418443 },
    { level: 134, exp: 38337870 },
    { level: 159, exp: 64511347 },
    { level: 184, exp: 100468768 },
    { level: 110, exp: 21000230 },
    { level: 135, exp: 39221755 },
    { level: 160, exp: 65734680 },
    { level: 185, exp: 102145330 },
    { level: 111, exp: 21592963 },
    { level: 136, exp: 40100624 },
    { level: 161, exp: 66999306 },
    { level: 186, exp: 103840438 },
    { level: 112, exp: 22184200 },
    { level: 137, exp: 41011298 },
    { level: 162, exp: 68280046 },
    { level: 187, exp: 105519225 },
    { level: 113, exp: 22798906 },
    { level: 138, exp: 41935654 },
    { level: 163, exp: 69550433 },
    { level: 188, exp: 107251356 },
    { level: 114, exp: 23424862 },
    { level: 139, exp: 42854473 },
    { level: 164, exp: 70863380 },
    { level: 189, exp: 109002337 },
    { level: 115, exp: 24048945 },
    { level: 140, exp: 43806220 },
    { level: 165, exp: 72192745 },
    { level: 190, exp: 110736170 },
    { level: 116, exp: 24697476 },
    { level: 141, exp: 44471953 },
    { level: 166, exp: 73511074 },
    { level: 191, exp: 112524776 },
    { level: 117, exp: 25357561 },
    { level: 142, exp: 45731610 },
    { level: 167, exp: 74873248 },
    { level: 192, exp: 114332536 },
    { level: 118, exp: 26015378 },
    { level: 143, exp: 46725336 },
    { level: 168, exp: 76252144 },
    { level: 193, exp: 116122303 },
    { level: 119, exp: 26698640 },
    { level: 144, exp: 47733352 },
    { level: 169, exp: 77619303 },
    { level: 194, exp: 117968290 },
    { level: 120, exp: 27393760 },
    { level: 145, exp: 48734735 },
    { level: 170, exp: 79031610 },
    { level: 195, exp: 119833735 },
    { level: 121, exp: 28086199 },
    { level: 146, exp: 49771346 },
    { level: 171, exp: 80460943 },
    { level: 196, exp: 121680324 },
    { level: 122, exp: 28805098 },
    { level: 147, exp: 50822551 },
    { level: 172, exp: 81877820 },
    { level: 197, exp: 123584598 },
    { level: 123, exp: 29536159 },
    { level: 148, exp: 51866548 },
    { level: 173, exp: 83341166 },
    { level: 198, exp: 125508634 },
    { level: 124, exp: 30264108 },
    { level: 149, exp: 52946950 },
    { level: 174, exp: 84821842 },
    { level: 199, exp: 127412933 },
    { level: 125, exp: 31019550 },
    { level: 150, exp: 54042250 },
    { level: 175, exp: 86289325 },
    { level: 200, exp: 129376400 },
    { level: 201, exp: 131359933 },
    { level: 226, exp: 187308374 },
    { level: 251, exp: 257303916 },
    { level: 276, exp: 342869908 },
    { level: 202, exp: 133322830 },
    { level: 227, exp: 189845348 },
    { level: 252, exp: 260437516 },
    { level: 277, exp: 346586355 },
    { level: 203, exp: 135346396 },
    { level: 228, exp: 192405124 },
    { level: 253, exp: 263532443 },
    { level: 278, exp: 350406846 },
    { level: 204, exp: 137390332 },
    { level: 229, exp: 194935363 },
    { level: 254, exp: 266716310 },
    { level: 279, exp: 354255307 },
    { level: 205, exp: 139412715 },
    { level: 230, exp: 197540590 },
    { level: 255, exp: 269925715 },
    { level: 280, exp: 358053440 },
    { level: 206, exp: 141497286 },
    { level: 231, exp: 200168923 },
    { level: 256, exp: 273095224 },
    { level: 281, exp: 361957586 },
    { level: 207, exp: 143602531 },
    { level: 232, exp: 202766640 },
    { level: 257, exp: 276355498 },
    { level: 282, exp: 365890006 },
    { level: 208, exp: 145685288 },
    { level: 233, exp: 205441026 },
    { level: 258, exp: 279641614 },
    { level: 283, exp: 369770713 },
    { level: 209, exp: 147831770 },
    { level: 234, exp: 208138822 },
    { level: 259, exp: 282886593 },
    { level: 284, exp: 373759420 },
    { level: 210, exp: 149999230 },
    { level: 235, exp: 210804905 },
    { level: 260, exp: 286224180 },
    { level: 285, exp: 377776705 },
    { level: 211, exp: 152143249 },
    { level: 236, exp: 213549356 },
    { level: 261, exp: 289587913 },
    { level: 286, exp: 381740874 },
    { level: 212, exp: 154352548 },
    { level: 237, exp: 216317521 },
    { level: 262, exp: 292909250 },
    { level: 287, exp: 385815048 },
    { level: 213, exp: 156583129 },
    { level: 238, exp: 219052858 },
    { level: 263, exp: 296325056 },
    { level: 288, exp: 389918104 },
    { level: 214, exp: 158789298 },
    { level: 239, exp: 221868280 },
    { level: 264, exp: 299767312 },
    { level: 289, exp: 393966623 },
    { level: 215, exp: 161062320 },
    { level: 240, exp: 224707720 },
    { level: 265, exp: 303165895 },
    { level: 290, exp: 398127170 },
    { level: 216, exp: 163356928 },
    { level: 241, exp: 227531199 },
    { level: 266, exp: 306660826 },
    { level: 291, exp: 402316903 },
    { level: 217, exp: 165626135 },
    { level: 242, exp: 230400498 },
    { level: 267, exp: 310182511 },
    { level: 292, exp: 406450660 },
    { level: 218, exp: 167963786 },
    { level: 243, exp: 233312119 },
    { level: 268, exp: 313659228 },
    { level: 293, exp: 410698486 },
    { level: 219, exp: 170323327 },
    { level: 244, exp: 236188628 },
    { level: 269, exp: 317234190 },
    { level: 294, exp: 414975802 },
    { level: 220, exp: 172656460 },
    { level: 245, exp: 239148710 },
    { level: 270, exp: 320836210 },
    { level: 295, exp: 419195685 },
    { level: 221, exp: 175059646 },
    { level: 246, exp: 242133418 },
    { level: 271, exp: 324391949 },
    { level: 296, exp: 423531696 },
    { level: 222, exp: 177485026 },
    { level: 247, exp: 245081845 },
    { level: 272, exp: 328047848 },
    { level: 297, exp: 427897501 },
    { level: 223, exp: 179882973 },
    { level: 248, exp: 248115616 },
    { level: 273, exp: 331731109 },
    { level: 298, exp: 432204398 },
    { level: 224, exp: 182352600 },
    { level: 249, exp: 251174317 },
    { level: 274, exp: 335366758 },
    { level: 299, exp: 436629500 },
    { level: 225, exp: 184844725 },
    { level: 250, exp: 254195550 },
    { level: 275, exp: 339104500 },
    { level: 300, exp: 441084700 },
]
