import { Location } from './location'

export type Task = {
    npc: string
    exp: number
    task: string
    location: Location
    done?: boolean
    mobsExp?: number
    catchExp?: number
}

export const lvl300Tasks: Task[] = [
    {
        npc: 'NPC Alessa',
        location: Location.PHENAC,
        task: '300 Sceptile, 300 Blaziken e 300 Swampert ',
        exp: 1969.2,
        mobsExp: 900 * 0.912,
        catchExp: 900,
    },
    {
        npc: 'NPC Salvatore',
        location: Location.PHENAC,
        task: '150 Dusclops, 150 Sableye e 200 Banette ',
        exp: 981.8,
        mobsExp: 500 * 0.912,
        catchExp: 900,
    },
    {
        npc: 'NPC Antonella',
        location: Location.PHENAC,
        task: '200 Claydol e 200 Grumpig',
        exp: 875.2,
        mobsExp: 400 * 0.912,
        catchExp: 600,
    },
    {
        npc: 'NPC Greggy',
        location: Location.PHENAC,
        task: '300 Medicham e 100 Meditite ',
        exp: 649.8,
        mobsExp: 300 * 0.912,
        catchExp: 300,
    },
    {
        npc: 'NPC Yggrith',
        location: Location.PHENAC,
        task: '450 Cacturne, 100 Cacnea',
        exp: 966.15,
        mobsExp: 450 * 0.912,
        catchExp: 0,
    },
    {
        npc: 'NPC Roger',
        location: Location.PHENAC,
        task: '350 Exploud, 250 Loudred, 100 Whismur',
        exp: 966.15,
        mobsExp: 350 * 0.912,
        catchExp: 0,
    },
    {
        npc: 'NPC Anice',
        location: Location.PHENAC,
        task: '200 Swellow e 200 Altaria',
        exp: 875.2,
        mobsExp: 400 * 0.912,
        catchExp: 0,
    },
    {
        npc: 'NPC Austin',
        location: Location.PHENAC,
        task: '150 Seviper e 150 Zangoose',
        exp: 656.4,
        mobsExp: 300 * 0.912,
        catchExp: 0,
    },
    {
        npc: 'NPC Khyara',
        location: Location.PHENAC,
        task: '400 Ludicolo e 200 Lombre ',
        exp: 1076.8,
        mobsExp: 400 * 0.912,
        catchExp: 300,
    },
    {
        npc: 'NPC Victorine',
        location: Location.PHENAC,
        task: '200 Shelgon, 200 Metang e 200 Mawile',
        exp: 1312.8,
        mobsExp: 600 * 0.912,
        catchExp: 0,
    },
    {
        npc: 'NPC Enrico',
        location: Location.AGATE,
        task: '300 Shiftry e 150 Nuzleaf',
        exp: 732,
        mobsExp: 300 * 0.912,
        catchExp: 300,
    },
    {
        npc: 'NPC Enrico',
        location: Location.AGATE,
        task: '250 Vigoroth e 100 Slakoth',
        exp: 410.4,
        mobsExp: 250 * 0.912,
        catchExp: 0,
    },
    {
        npc: 'NPC Charlene',
        location: Location.AGATE,
        task: '300 Flygon e 100 Vibrava ',
        exp: 757.2,
        mobsExp: 300 * 0.912,
        catchExp: 0,
    },
    {
        npc: 'NPC Goulard',
        location: Location.AGATE,
        task: '350 Crawdaunt e 200 Corphish',
        exp: 909.8,
        mobsExp: 350 * 0.912,
        catchExp: 300,
    },
    {
        npc: 'NPC Manuele',
        location: Location.AGATE,
        task: '350 Camerupt e 200 Numel ',
        exp: 909.8,
        mobsExp: 350 * 0.912,
        catchExp: 300,
    },
    {
        npc: 'NPC Luck',
        location: Location.AGATE,
        task: '450 Manectric e 100 Electrike',
        exp: 974.7,
        mobsExp: 450 * 0.912,
        catchExp: 300,
    },
    {
        npc: 'NPC Gordon',
        location: Location.AGATE,
        task: '425 Mightyena, 100 Poochyena',
        exp: 940.5,
        mobsExp: 425 * 0.912,
        catchExp: 0,
    },
    {
        npc: 'NPC Susan',
        location: Location.GATEON,
        task: '350 Mightyena',
        exp: 718.2,
        mobsExp: 0,
        catchExp: 0,
    },
    {
        npc: 'NPC Chow',
        location: Location.OUTLAND_NORTH,
        task: '250 Elder Pokémon da Outland North ',
        exp: 4000,
        mobsExp: 250 * 2,
        catchExp: 0,
    },
    {
        npc: 'NPC Carrie',
        location: Location.OUTLAND_NORTH,
        task: 'Derrotar 10.000 Pokémon de água',
        exp: 15000,
        mobsExp: 10000 * 0.912,
        catchExp: 0,
    },
    {
        npc: 'NPC Nereu',
        location: Location.OUTLAND_NORTH,
        task: 'Assistir: Cinemática sobre a história da Outland. ',
        exp: 5000,
        mobsExp: 0,
        catchExp: 0,
    },
    {
        npc: 'NPC Alfie',
        location: Location.OUTLAND_SOUTH,
        task: '250 Elder Pokémon da Outland South ',
        exp: 4000,
        mobsExp: 250 * 2,
        catchExp: 0,
    },
    {
        npc: 'NPC Reyna',
        location: Location.OUTLAND_SOUTH,
        task: 'Derrotar 10.000 Pokémon de raio ',
        exp: 15000,
        mobsExp: 10000 * 0.912,
        catchExp: 0,
    },
    {
        npc: 'NPC Rohan',
        location: Location.OUTLAND_SOUTH,
        task: 'Assistir: Cinemática sobre a história da Outland. ',
        exp: 5000,
        mobsExp: 0,
        catchExp: 0,
    },
    {
        npc: 'NPC OUTLAND_SOUTH',
        location: Location.OUTLAND_EAST,
        task: '250 Elder Pokémon da Outland East',
        exp: 4000,
        mobsExp: 250 * 2,
        catchExp: 0,
    },
    {
        npc: 'NPC Corey',
        location: Location.OUTLAND_EAST,
        task: 'Derrotar 10.000 Pokémon de fogo',
        exp: 15000,
        mobsExp: 10000 * 0.912,
        catchExp: 0,
    },
    {
        npc: 'NPC Silva',
        location: Location.OUTLAND_EAST,
        task: '500 Pokémon do tipo lutador na Outland (Hitmontop, Hitmonlee e Hitmonchan). ',
        exp: 1094,
        mobsExp: 500 * 0.912,
        catchExp: 0,
    },
    {
        npc: 'NPC Zion',
        location: Location.OUTLAND_EAST,
        task: 'Assistir: Cinemática sobre a história da Outland. ',
        exp: 5000,
        mobsExp: 0,
        catchExp: 0,
    },
]

export const lvl150Tasks: Task[] = [
    {
        npc: 'NPC Royce',
        location: Location.CINNABAR,
        task: '90 Magmar',
        exp: 121.5,
        mobsExp: 90 * 0.742,
        catchExp: 0,
    },
    {
        npc: 'NPC Xavier',
        location: Location.CERULEAN,
        task: '60 Golem, 60 Rhydon, 60 Marowak e 60 Sandslash',
        exp: 256.5,
        mobsExp: 0,
        catchExp: 0,
    },
    {
        npc: 'NPC Zulu',
        location: Location.BUTTERFREE_ISLAND,
        task: '300 Venusaur e 300 Meganium ',
        exp: 800,
        mobsExp: 600 * 0.742,
        catchExp: 600,
    },
    {
        npc: 'NPC Caesar',
        location: Location.BUTTERFREE_ISLAND,
        task: '300 Charizard e 300 Typhlosion ',
        exp: 800,
        mobsExp: 600 * 0.742,
        catchExp: 600,
    },
    {
        npc: 'NPC Li Mu Bai',
        location: Location.BUTTERFREE_ISLAND,
        task: '300 Blastoise e 300 Feraligatr ',
        exp: 800,
        mobsExp: 600 * 0.742,
        catchExp: 600,
    },
    {
        npc: 'NPC Margarida',
        location: Location.LEAF_ISLAND,
        task: 'Capturar e entregar: Xatu ',
        exp: 150,
        mobsExp: 200 * 0.742,
        catchExp: 300,
    },
    {
        npc: 'NPC Melvin',
        location: Location.LEAF_ISLAND,
        task: 'Encontrar: Procurar sua capa de mágico. Coordenadas: 3695, 4206, 8',
        exp: 100,
    },
    {
        npc: 'NPC Tyler',
        location: Location.SHAMOUTI,
        task: '150 Hitmonlee, 150 Hitmonchan e 150 Hitmontop ',
        exp: 354.375,
    },
    {
        npc: 'NPC Eugene',
        location: Location.SHAMOUTI,
        task: '160 Raichu, 100 Pikachu e 50 Pichu ',
        exp: 312,
    },
    {
        npc: 'NPC Minerva',
        location: Location.SHAMOUTI,
        task: '30 Dragonite e 350 Alakazam ',
        exp: 850,
        mobsExp: 350 * 0.742,
        catchExp: 300,
    },
    {
        npc: 'NPC Yuri',
        location: Location.BUTWAL,
        task: '90 Meganium, 60 Bayleef e 60 Chikorita ',
        exp: 162,
    },
    {
        npc: 'NPC Antoni',
        location: Location.MAGMA_ISLAND,
        task: '150 Donphan',
        exp: 202.625,
        mobsExp: 150 * 0.742,
        catchExp: 300,
    },
    {
        npc: 'NPC Joel',
        location: Location.MANDARIN,
        task: '100 Machoke e 200 Machamp ',
        exp: 350,
        catchExp: 300,
    },
    {
        npc: 'NPC Bauer',
        location: Location.MURCOTT,
        task: '300 Forretress ',
        exp: 405,
        mobsExp: 300 * 0.742,
        catchExp: 300,
    },
    {
        npc: 'NPC Nadia',
        location: Location.MURCOTT,
        task: '150 Pinsir e 150 Heracross',
        exp: 405,
        mobsExp: 300 * 0.742,
    },
    {
        npc: 'NPC Philip',
        location: Location.MURCOTT,
        task: '500 Kricketune e 2 Shiny Kricketune. ',
        exp: 775,
        mobsExp: 500 * 0.742,
        catchExp: 300,
    },
    {
        npc: 'NPC Boyega',
        location: Location.TANGELO_ISLAND,
        task: '300 Golem.',
        exp: 405,
        mobsExp: 300 * 0.742,
        catchExp: 300,
    },
    {
        npc: 'NPC Robb',
        location: Location.PETALGROOVE_ISLAND,
        task: '250 Clefable e 250 Granbull. ',
        exp: 675,
        mobsExp: 500 * 0.742,
        catchExp: 600,
    },
    {
        npc: 'NPC Anice',
        location: Location.ORRE,
        task: '200 Swellow e 200 Altaria ',
        exp: 875.2,
        mobsExp: 400 * 0.742,
        catchExp: 300,
    },
    {
        npc: 'NPC Galvao',
        location: Location.ORRE,
        task: '500 Glalie ',
        exp: 1094,
        mobsExp: 500 * 0.742,
        catchExp: 300,
    },
    {
        npc: 'NPC Good Game Niko ',
        location: Location.CREATION_STUDIO,
        task: '22 Lapras, 30 Electabuzz, 41 Arcanine, 6 Charizard e 8 Gengar ',
        exp: 200,
    },
    {
        npc: 'NPC Teedzada',
        location: Location.CREATION_STUDIO,
        task: '500 Pokémon do tipo lutador ',
        exp: 600,
        mobsExp: 500 * 0.742,
    },
    {
        npc: 'NPC Sharxera',
        location: Location.CREATION_STUDIO,
        task: 'Derrotar 100 Machamp, 100 Hitmonlee, 100 Hitmonchan e 100 Hitmontop. ',
        exp: 371.25,
    },
    {
        npc: 'NPC Hidaree',
        location: Location.CREATION_STUDIO,
        task: '1º Parte Responder à pergunta, resposta: Metagross \n2º Parte Duelar contra um Electivire \n3º Parte Responder à pergunta, resposta: Forretress \n4º Parte Duelar contra um Mega Charizard X \n5º Parte Responder à pergunta, resposta: Garchomp ',
        exp: 500,
    },
]
